import React from 'react';
import cx from 'classnames';

interface GithubSvgProps {
	pathClass?: string;
}

export const GithubSvg: React.FC<GithubSvgProps> = ({ pathClass: svgClass }) => (
	<svg width='32' height='32' viewBox='0 0 36 35.109'>
		<path
			id='Icon_simple-github'
			data-name='Icon simple-github'
			d='M18,.445a18,18,0,0,0-5.693,35.077c.9.169,1.23-.387,1.23-.866,0-.428-.015-1.56-.023-3.06-5.007,1.086-6.063-2.415-6.063-2.415a4.771,4.771,0,0,0-2-2.632c-1.63-1.116.126-1.094.126-1.094A3.774,3.774,0,0,1,8.333,27.31a3.836,3.836,0,0,0,5.243,1.5,3.838,3.838,0,0,1,1.14-2.407c-4-.45-8.2-2-8.2-8.9a6.944,6.944,0,0,1,1.852-4.83,6.4,6.4,0,0,1,.158-4.764s1.507-.483,4.95,1.845a16.97,16.97,0,0,1,9,0C25.9,7.428,27.4,7.911,27.4,7.911a6.577,6.577,0,0,1,.18,4.764,6.973,6.973,0,0,1,1.845,4.83c0,6.915-4.208,8.438-8.212,8.88a4.309,4.309,0,0,1,1.215,3.33c0,2.409-.022,4.344-.022,4.929,0,.472.315,1.035,1.237.855A17.978,17.978,0,0,0,18,.445'
			transform='translate(0 -0.446)'
			className={cx('fill-rose-900 dark:fill-white', svgClass ? svgClass : '')}
		/>
	</svg>
);
